import React from 'react';
import ReactDOM from 'react-dom';
import './layout/index.scss';
import * as Sentry from '@sentry/react';
import App from './App';
import * as serviceWorker from './serviceWorker';

if (window.location.hostname !== "localhost") {
    Sentry.init({dsn: "https://936f7f932973477ba03fed6cd5d06fba@o222939.ingest.sentry.io/5285553"});
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
