import React, {Component} from "react";
import {Loader, Table} from 'rimble-ui';
import SHA3 from 'sha3';
import { toUUID } from 'to-uuid'
import struct from "python-struct"
import {Uint64LE} from "int64-buffer"
import {Buffer} from "buffer"
import Web3 from "web3";
import {getName, registerLocale} from "i18n-iso-countries"

import {getWeb3} from './utils/get_web3';
import styles from './App.module.scss';
import Header from './components/Header/index.js';
import Web3Info from './components/Web3Info/index.js';
import CounterUI from './components/Counter/index.js';
import MovieRecords from './contracts_abi/ArtiniiMovieRecords.json'


registerLocale(require("i18n-iso-countries/langs/en.json"));

class App extends Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidMount = async () => {
        try {
            // Get network provider and web3 instance.
            let chain = "mainnet"
            if (window.location.hostname === "localhost") {
                const urlParams = new URLSearchParams(window.location.search);
                const specified_chain = urlParams.get('chain')
                if (specified_chain) {
                    chain = specified_chain
                }
            } else if (window.location.hostname.includes("dev")) {
                chain = "ropsten"
            }
            console.log(chain)
            const fallbackProvider = new Web3.providers.WebsocketProvider(`wss://${chain}.infura.io/ws/v3/d0b2e3064d3743a189c5ed2989a93bb9`);
            const web3 = await getWeb3({fallbackProvider});
            // Get the contract instance.
            const networkId = await web3.eth.net.getId();
            const networkType = await web3.eth.net.getNetworkType();
            const isMetaMask = web3.currentProvider.isMetaMask;
            let instance = null;
            let deployedNetwork = null;
            let nonce = 0;
            let seed = null;
            let records = [];
            if (MovieRecords.networks) {
                deployedNetwork = MovieRecords.networks[networkId.toString()];
                if (deployedNetwork) {
                    instance = new web3.eth.Contract(MovieRecords.abi, deployedNetwork && deployedNetwork.address);
                }
            }
            console.log(instance);
            if (instance) {
                // Set web3, accounts, and contract to the state, and then proceed with an
                // example of interacting with the contract's methods.
                this.setState(
                    {
                        web3,
                        networkId,
                        networkType,
                        isMetaMask,
                        nonce,
                        seed,
                        records,
                        contract: instance,
                    },
                );
            } else {
                this.setState({
                    web3,
                    networkId,
                    networkType,
                    isMetaMask,
                    nonce,
                    seed,
                    records,
                });
            }
        } catch (error) {
            // Catch any errors for any of the above operations.
            alert(`Failed to load web3, accounts, or contract. Check console for details.`);
            console.error(error);
        }
    };

    getCount = async () => {
        let {contract, nonce, seed, records} = this.state;
        const encryption_key = await crypto.subtle.importKey("raw", Buffer.from(seed.slice(64,128), "hex").buffer, "AES-CTR", false, ["decrypt"])
        seed = seed.slice(0,64)
        for (;;) {
            const hash = new SHA3(256);
            hash.update(seed, "hex");

            const uint_nonce = new Uint64LE(nonce);
            console.log(uint_nonce);
            hash.update(uint_nonce.toBuffer());

            const key = "0x" + hash.digest('hex');
            console.log("Key is " + key);
            const response = await contract.methods.records(key).call();
            console.log(response);
            if (response === "0x0000000000000000000000000000000000000000000000000000000000000000")
                break;
            const version = parseInt(response.slice(2,4), 8)
            if(version === 1) {
                const unpacked = struct.unpack("!B16BdI", Buffer.from(response.slice(2), "hex"))
                console.log(unpacked)
                const order_item_guid = toUUID(Buffer.from(unpacked.slice(1, 17)).toString("hex"))
                let timestamp = unpacked[17]
                console.log(timestamp)
                timestamp = new Date(timestamp * 1000.0).toLocaleString();
                let level = unpacked[18]
                console.log(level)
                const record = {"nonce": nonce, "order_guid": order_item_guid, "timestamp": timestamp, "level": level, "country": "Not Available"};
                records.unshift(record);
            } else if(version === 2) {
                const unpacked = struct.unpack("!B16BdIH", Buffer.from(response.slice(2), "hex"))
                console.log(unpacked)
                const order_item_guid = toUUID(Buffer.from(unpacked.slice(1, 17)).toString("hex"))
                let timestamp = unpacked[17]
                console.log(timestamp)
                timestamp = new Date(timestamp * 1000.0).toLocaleString();
                let level = unpacked[18]
                console.log(level)
                const country_numeric = unpacked[19]
                const record = {"nonce": nonce, "order_guid": order_item_guid, "timestamp": timestamp, "level": level, "country": getName(country_numeric, "en")};
                records.unshift(record);
            } else if (version === 3) {
                const encrypted = Buffer.from(response.slice(4,58), "hex")
                const random_nonce = Buffer.from(response.slice(58,66), "hex")
                console.log(random_nonce.length)
                const counter = Buffer.concat([random_nonce, uint_nonce.toBuffer(), Buffer.alloc(4)])
                console.log(counter.length)
                const decrypted = await window.crypto.subtle.decrypt(
                            {
                              name: "AES-CTR",
                              counter,
                              length: 32
                            },
                            encryption_key,
                            encrypted
                          );
                console.log(decrypted)
                const unpacked = struct.unpack("!16BIIH", Buffer.from(decrypted))
                console.log(unpacked)
                const order_item_guid = toUUID(Buffer.from(unpacked.slice(0, 16)).toString("hex"))
                let timestamp = unpacked[16]
                console.log(timestamp)
                timestamp = new Date(timestamp * 1000.0).toLocaleString();
                let level = unpacked[17]
                console.log(level)
                const country_numeric = unpacked[18]
                console.log(country_numeric)
                const record = {"nonce": nonce, "order_guid": order_item_guid, "timestamp": timestamp, "level": level, "country": getName(country_numeric, "en")};
                records.unshift(record);
            } else {
                throw "Unsupported message version."
            }
            nonce++;
            this.setState({nonce: nonce, records: records});
        }
        // Update state with the result.
        console.log("Nonce is " + nonce);
        this.setState({nonce: nonce, records: records});
    };

    setSeed = async seed => {
        const {web3, contract} = this.state;
        if (seed != null)
            await this.setState({seed: seed});
        await this.getCount();
        web3.eth.subscribe('logs', {"address": contract.options.address}, this.getCount);
    };


    renderLoader() {
        return (
            <div className={styles.loader}>
                <Loader size="80px"/>
                <h3> Loading Web3, accounts, and contract...</h3>
                <p> Unlock your Metamask </p>
            </div>
        );
    }

    renderDeployCheck() {
        return (
            <div className={styles.setup}>
                <div className={styles.notice}>
                    Your <b> contracts are not deployed</b> in this network. Two potential reasons: <br/>
                    <p>
                        Maybe you are in the wrong network? Point Metamask to localhost.
                        <br/>
                        You contract is not deployed. Follow the instructions below.
                    </p>
                </div>
            </div>
        );
    }

    renderBody() {
        const TableRow = ({row}) => (
            <tr>
                <td>{row.nonce+1}</td>
                <td>{row.timestamp}</td>
                <td>{row.order_guid}</td>
                <td>{row.level}</td>
                <td>{row.country}</td>
            </tr>
        );

        return (
            <div className={styles.wrapper}>
                {!this.state.web3 && this.renderLoader()}
                {this.state.web3 && !this.state.contract && this.renderDeployCheck()}
                {this.state.web3 && this.state.contract && (
                    <div className={styles.contracts}>
                        <h1>Artinii Movie Screening History DApp</h1>
                        <div className={styles.widgets}>
                            <Web3Info {...this.state} />
                            <CounterUI setseed={this.setSeed} {...this.state} />
                        </div>
                        <p> Number of recorded screenings: {this.state.nonce} </p>
                        <p><Table>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Timestamp</th>
                                <th>Order GUID</th>
                                <th>How many people</th>
                                <th>Country</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.records.map(row => {
                                return <TableRow key={row.nonce} row={row}/>
                            })}
                            </tbody>
                        </Table></p>
                    </div>
                )}
            </div>
        );
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <Header/>
                    {this.renderBody()}
                </header>
            </div>
        );
    }
}

export default App;
