import React, { Component } from 'react';
import { EthAddress, Button, Input } from 'rimble-ui';
import styles from './Counter.module.scss';

export default class Counter extends Component {
    constructor(props) {
        super(props);
        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
  }

    validateForm(e) {
        document.getElementById('recordsButton').disabled = e.target.value.length !== 128;
    }

    handleClick() {
        console.log("Handling click");
        let seed = document.getElementById('seedInput').value;
        console.log(seed);
        this.props.setseed(seed);
    }

    render() {
        const {contract} = this.props;
        return (
            <div className={styles.counter}>
                <h3> ETH Contract instance </h3>
                <div className={styles.dataPoint}>
                    <div className={styles.label}>Instance address:</div>
                    <div className={styles.value}>
                        <EthAddress address={contract._address}/>
                    </div>
                </div>
                <div className={styles.dataPoint}>
                    <div className={styles.label}>Your key:</div>
                    <div className={styles.value}>
                        <Input type="text" id="seedInput" onChange={this.validateForm} required={true} placeholder="e.g. 604c5b07bb..."/>
                    </div>
                </div>
                <div className={styles.buttons}>
                    <Button id="recordsButton" onClick={this.handleClick}>
                        Get Records
                    </Button>
                </div>
            </div>
        );
    }
}
